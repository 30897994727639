
import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import RichText from '../components/richText';
import { Link } from "gatsby";

import PostsList from "../components/postsList";


const Blog = ({ data, pageContext }) => {

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    document.querySelector('html').classList.add('smooth');
  }, []);


  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;

  const $blog = data.prismicBlog ? data.prismicBlog.data : null;
  const $postsList = data.allPrismicBlogPost ? data.allPrismicBlogPost.edges : null;
  const $categoriesList = data.allPrismicBlogCategory ? data.allPrismicBlogCategory.nodes : null;
  


  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs}>

      <Seo metadata={$metaData} title={$blog.page_title ? $blog.page_title : null} description={$blog.page_description ? $blog.page_description : null} keywords={$blog.page_keywords ? $blog.page_keywords : null}></Seo>

      <Helmet>

        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/blog.pager.css')} rel="stylesheet" type="text/css" />

      </Helmet>


      <main>

        <section>


          <div className="c-blog-hero c-hero o-wrapper o-wrapper--xl c-heading">
            <h2 className="c-blog-heading__kicker c-heading__kicker">{$blog.caption}</h2>
            {$blog.title &&
              <div className="c-blog-heading__heading c-heading__heading">
                <RichText render={$blog.title.richText} />
              </div>
            }
          </div>

          <div className="c-blog__wrapper">

            <div className="o-wrapper o-wrapper--xl">
            <div className={`c-blog__holder  ${pageContext.currentPage != 1 ? 'is-paginated' : ''} `}>

              <nav className="c-blog__nav c-blog-nav">

                  <ul className="c-blog-nav__list">

                    <li className="c-blog-nav__item">
                      <Link to={`/blog`} className={`c-blog-nav__link u-tap-size is-active`}>All categories</Link>
                    </li>

                    {$categoriesList.map((item, i) => {

                      return (
                        <li className="c-blog-nav__item">
                          <Link to={`${item.url}`} className={`c-blog-nav__link u-tap-size`}>{item.data.name}</Link>
                        </li>
                      )

                    })}
                  </ul>

                  <h2 className="u-hidden-for-seo">Categories Navigation</h2> 

              </nav>

              <PostsList postsList={$postsList} pageContext={pageContext}  highlightFirst={pageContext.currentPage == 1} template={pageContext.uid}/>

            </div>

          </div>

          </div>

        </section>

      </main>


    </Layout>


  )
}

export const query = graphql`
  query blogQuery($skip: Int!, $limit: Int!) {
    prismicBlog {
      data {
        page_title
        page_keywords
        page_description
        caption
        title {
          richText
        }
      }
      uid
    },
    allPrismicBlogPost(
      sort: {fields: first_publication_date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
         uid
         url
         first_publication_date
         last_publication_date
         data{
           title
           description
           image_preview {
            gatsbyImageData(
              
              breakpoints: [414,767,768,980,1024,1440,1680,1920]
            )
            url
          }
           image {
            gatsbyImageData(
              
              breakpoints: [414,767,768,980,1024,1440,1680,1920]
            )
            url
          }
           category {
            url
            uid
            document {
              ... on PrismicBlogCategory {
                data {
                  name
                }
              }
            }
          }
           author {
            url
            uid
            document {
              ... on PrismicBlogAuthor {
                data {
                  name
                  image {
                    gatsbyImageData(
                      
                      breakpoints: [414,767,768,980,1024,1440,1680,1920]
                    )
                    url
                  }
                }
              }
            }
          }
         }
        }
      }
    },
    allPrismicBlogCategory {
      nodes {
        data {
          name
        }
        uid
        url
      }
    }
    
    prismicMetadata {
      ...MetadataFragment
    },   
    prismicClutch {
      ...ClutchFragment
    },
    prismicFooter{
      ...FooterFragment   
    }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment  
    }
    
  }
`

export default Blog
